import { useRegisterSW } from "virtual:pwa-register/react";
import { LazyExoticComponent, Suspense, lazy, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Navigate, Route, Routes } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Toaster } from "sonner";
import { Button } from "@archery-inc/design-system";

const SharedVideoPage = lazy(() => import("./pages/SharedVideoPage"));
const TeamInvitationPage = lazy(() => import("./pages/TeamInvitationPage"));
const SettingsPage = lazy(() => import("./pages/SettingsPage"));
const AppLayout = lazy(() => import("./layouts/AppLayout"));
const NavigationLayout = lazy(() => import("./layouts/NavigationLayout"));
const OnboardingLayout = lazy(() => import("./layouts/OnboardingLayout"));
const PrivateRoutesLayout = lazy(() => import("./layouts/PrivateRoutesLayout"));
const SubscribeToProPage = lazy(() => import("./pages/SubscribeToProPage"));
const SubscribeToTeamsPage = lazy(() => import("./pages/SubscribeToTeamsPage"));
const ManageSubscriptionPage = lazy(
  () => import("./pages/ManageSubscriptionPage"),
);
const TemplatesPage = lazy(() => import("./pages/TemplatesPage"));
const MyProjectsPage = lazy(() => import("./pages/ProjectsPage"));
const BrandKitPage = lazy(() => import("./pages/BrandKitPage"));
const LoginLayout = lazy(() => import("./layouts/LoginLayout"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));

type AppLayoutComponent = (props: {
  children: JSX.Element | JSX.Element[];
}) => JSX.Element | null;

interface AppProps {
  forceLogin?: boolean;
  layout?: LazyExoticComponent<AppLayoutComponent> | AppLayoutComponent;
}

export default function App({
  layout: Layout = AppLayout,
  forceLogin = false,
}: AppProps) {
  return (
    <Suspense fallback={null}>
      <Layout>
        <Routes>
          <Route element={<PrivateRoutesLayout forceLogin={forceLogin} />}>
            <Route element={<NavigationLayout />}>
              <Route path="/" element={<TemplatesPage />} />
              <Route path="/templates" element={<TemplatesPage />} />
              <Route path="/my-projects" element={<MyProjectsPage />} />
              <Route path="/brand-kit" element={<BrandKitPage />} />
              <Route
                path="/invite/:id"
                element={<TeamInvitationPage sendFullUrl />}
              />
              <Route
                path="/team-invitation/:id"
                element={<TeamInvitationPage />}
              />
              <Route path="/settings/:tab" element={<SettingsPage />} />
            </Route>
            <Route element={<OnboardingLayout />}>
              <Route
                path="/manage-subscription"
                element={<ManageSubscriptionPage />}
              />
              <Route
                path="/subscribe/:status?"
                element={<SubscribeToProPage />}
              />
              <Route
                path="/subscribe-teams/:status?"
                element={<SubscribeToTeamsPage />}
              />
            </Route>
          </Route>
          <Route element={<LoginLayout />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/teams-login" element={<LoginPage isTeams />} />
            <Route path="/reset" element={<ResetPasswordPage />} />
          </Route>
          <Route path="/v/:exportId" element={<SharedVideoPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Toaster position="top-right" />
        <ReloadPrompt />
      </Layout>
    </Suspense>
  );
}

function ReloadPrompt() {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW();
  const [isLoading, setLoading] = useState(false);

  const close = () => {
    setNeedRefresh(false);
  };

  const reload = async () => {
    setLoading(true);
    await updateServiceWorker(true);
    setNeedRefresh(false);
    setLoading(false);
  };

  return (
    <div>
      <AnimatePresence>
        {needRefresh && (
          <motion.div
            initial={{
              y: "100%",
            }}
            animate={{
              y: 0,
            }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className="fixed bottom-2 right-2 rounded-3 p-4 bg-white shadow-y-plus-1"
          >
            <div>
              <FormattedMessage
                id="reload_popup_message"
                defaultMessage="New content available, click on reload button to update."
              />
            </div>
            <div className="flex gap-2 mt-2">
              <Button isDisabled={isLoading} onPress={() => void reload()}>
                <FormattedMessage
                  id="reload_popup_reload"
                  defaultMessage="Reload"
                />
              </Button>
              <Button color="grey" onPress={() => close()}>
                <FormattedMessage
                  id="reload_popup_close"
                  defaultMessage="Close"
                />
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
